import classNames from 'classnames';
import { SpringValue, animated } from 'react-spring';
import styled, { keyframes } from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { absCenter } from '@sorare/core/src/lib/style';

import { DURATION_TO_STEP_3 } from '../useCardXpProgressBarSprings';

const splashBubbles = keyframes`
	0% {
    opacity: 0;
    box-shadow: 0.32476rem -3rem 0 -0.1875rem #00fff7, -0.32476rem -2.625rem 0 -0.1875rem #00fff7, 2.54798rem -1.61656rem 0 -0.1875rem #00fff7, 1.84982rem -1.89057rem 0 -0.1875rem #00fff7, 2.85252rem 0.98418rem 0 -0.1875rem #00fff7, 2.63145rem 0.2675rem 0 -0.1875rem #00fff7, 1.00905rem 2.84381rem 0 -0.1875rem #00fff7, 1.43154rem 2.22414rem 0 -0.1875rem #00fff7, -1.59425rem 2.562rem 0 -0.1875rem #00fff7, -0.84635rem 2.50595rem 0 -0.1875rem #00fff7, -2.99705rem 0.35095rem 0 -0.1875rem #00fff7, -2.48692rem 0.90073rem 0 -0.1875rem #00fff7, -2.14301rem -2.12438rem 0 -0.1875rem #00fff7, -2.25479rem -1.38275rem 0 -0.1875rem #00fff7;
  }
  20% { opacity: 1; }
	75% {
		opacity: 1;
    box-shadow: 0.32476rem -2.4375rem 0 0rem #00fff7, -0.32476rem -2.0625rem 0 0rem #00fff7, 2.1082rem -1.26585rem 0 0rem #00fff7, 1.41004rem -1.53985rem 0 0rem #00fff7, 2.30412rem 0.85901rem 0 0rem #00fff7, 2.08305rem 0.14233rem 0 0rem #00fff7, 0.76499rem 2.33702rem 0 0rem #00fff7, 1.18748rem 1.71734rem 0 0rem #00fff7, -1.35019rem 2.0552rem 0 0rem #00fff7, -0.60229rem 1.99916rem 0 0rem #00fff7, -2.44865rem 0.22578rem 0 0rem #00fff7, -1.93852rem 0.77557rem 0 0rem #00fff7, -1.70323rem -1.77366rem 0 0rem #00fff7, -1.81501rem -1.03204rem 0 0rem #00fff7;
  }
`;
const colorsSwap = keyframes`
  0% {
    background-color: var(--c-white);
    color: var(--c-black);
    border-color: var(--c-nd-50);
  }
  50% {
    background-color: var(--c-rivals-friendlies);
    color: var(--c-black);
    border-color: var(--c-black);
  }
  100% {
    background-color: var(--c-black);
    color: var(--c-white);
    border-color: var(--c-nd-50);
  }
`;
const Root = styled(Horizontal).attrs({ gap: 0 })`
  isolation: isolate;
  width: 100%;
`;
const Bar = styled(Horizontal)`
  background-color: var(--c-black);
  border: 2px solid var(--c-nd-50);
  height: var(--triple-unit);
  width: 100%;
  border-radius: 0 100px 100px 0;
  padding: 5px;
  margin-left: calc(-1 * var(--unit));

  .small & {
    height: var(--intermediate-unit);
    border-width: 1px;
    padding: 2px;
  }
`;
const Filled = styled(animated.div)`
  background-color: var(--c-rivals-friendlies);
  height: 100%;
  width: 100%;
  border-radius: 0 100px 100px 0;
`;
const LevelWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid var(--c-nd-50);
  font-family: var(--brand-font);

  &.animate {
    animation: 1s ${colorsSwap} ease-in-out forwards;
  }

  .small & {
    width: var(--double-unit);
    height: var(--double-unit);
    font-size: 8px;
    border-width: 1px;
  }
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const Splash = styled.div`
  ${absCenter}
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: '';
    ${absCenter}
    width: 0.375rem;
    height: 0.375rem;
    z-index: -1;
    border-radius: 50%;
    animation: 1000ms ${splashBubbles} ${DURATION_TO_STEP_3}ms
      cubic-bezier(0.35, 0.03, 0.99, 0.65) reverse forwards;
  }
`;

type Props = {
  level: SpringValue<number>;
  percentage: SpringValue<number>;
  backgroundColor?: SpringValue<string>;
  color?: SpringValue<string>;
  borderColor?: SpringValue<string>;
  size?: 'small' | 'medium';
  withSplash?: boolean;
};
export const CardXpProgressBar = ({
  level,
  percentage,
  backgroundColor,
  color,
  borderColor,
  size = 'medium',
  withSplash,
}: Props) => {
  return (
    <Root className={classNames(size)}>
      <RelativeWrapper>
        {withSplash && <Splash />}
        <LevelWrapper style={{ backgroundColor, color, borderColor }}>
          {level.to(x => Math.round(x))}
        </LevelWrapper>
      </RelativeWrapper>
      <Bar>
        <Filled style={{ width: percentage.to(x => `${x}%`) }} />
      </Bar>
    </Root>
  );
};
