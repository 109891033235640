import { type TypedDocumentNode, gql } from '@apollo/client';
import styled, { CSSProperties } from 'styled-components';

import { BodyM, HeadlineS } from '@sorare/core/src/atoms/typography';

import type {
  SpecialEventTitleAndDesc_so5Fixture,
  SpecialEventTitleAndDesc_so5LeaderboardGroupInterface,
  SpecialEventTitleAndDesc_so5League,
} from './__generated__/index.graphql';

const SpecialEventTitle = styled(HeadlineS).attrs({ bold: true })`
  text-shadow: 0 0 var(--unit) var(--color);
`;

type Props = {
  so5Fixture: SpecialEventTitleAndDesc_so5Fixture;
  so5League?:
    | SpecialEventTitleAndDesc_so5League
    | SpecialEventTitleAndDesc_so5LeaderboardGroupInterface;
};

export const SpecialEventTitleAndDesc = ({ so5Fixture, so5League }: Props) => {
  return (
    <div>
      {so5Fixture.event?.title && (
        <SpecialEventTitle
          bold
          style={
            {
              '--color': so5League?.primaryColor,
            } as CSSProperties
          }
        >
          {so5Fixture.event.title}
        </SpecialEventTitle>
      )}
      <BodyM color="var(--c-nd-700)">
        {so5Fixture.event?.description || so5League?.description}
      </BodyM>
    </div>
  );
};

SpecialEventTitleAndDesc.fragments = {
  so5Fixture: gql`
    fragment SpecialEventTitleAndDesc_so5Fixture on So5Fixture {
      slug
      event {
        title
        description
      }
    }
  ` as TypedDocumentNode<SpecialEventTitleAndDesc_so5Fixture>,
  so5League: gql`
    fragment SpecialEventTitleAndDesc_so5League on So5League {
      slug
      primaryColor: color(variant: PRIMARY)
      description
    }
  ` as TypedDocumentNode<SpecialEventTitleAndDesc_so5League>,
  so5LeaderboardGroupInterface: gql`
    fragment SpecialEventTitleAndDesc_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
      slug
      primaryColor: color(variant: PRIMARY)
      description
    }
  ` as TypedDocumentNode<SpecialEventTitleAndDesc_so5LeaderboardGroupInterface>,
};
