import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { So5State } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { HeadlineL, HeadlineS } from '@sorare/core/src/atoms/typography';
import { FOOTBALL_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

import { Header } from '../Header';

const Root = styled(Vertical).attrs({ gap: 3 })``;
const CenteredFlexContainer = styled(Vertical).attrs({ gap: 2, center: true })`
  align-self: center;
  justify-content: flex-start;
`;

type Props = {
  errors?: { message: string }[];
  loading: boolean;
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  postAction?: 'lobby' | 'email';
};

export const Form = ({
  loading,
  errors,
  title,
  subtitle,
  postAction,
}: Props) => {
  return (
    <Root>
      <Header />
      {loading && <LoadingIndicator />}
      <CenteredFlexContainer>
        {errors?.length === 0 && (
          <>
            {title && (
              <HeadlineL as="h3">
                <FormattedMessage {...title} />
              </HeadlineL>
            )}
            {subtitle && (
              <HeadlineS as="h5">
                <FormattedMessage {...subtitle} />
              </HeadlineS>
            )}
          </>
        )}
        {errors?.map(({ message }) => (
          <HeadlineL as="h3" key={message}>
            {message}
          </HeadlineL>
        ))}
        {postAction === 'lobby' && (
          <Button
            to={generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
              fixture: So5State.UPCOMING.toLowerCase(),
            })}
            color="primary"
            size="medium"
          >
            <FormattedMessage
              id="NoCardEntry.Form.Cta.title"
              defaultMessage="Go back to the lobby"
            />
          </Button>
        )}
        {postAction === 'email' && (
          <HeadlineL as="h3">
            <FormattedMessage
              id="NoCardEntry.Form.email"
              defaultMessage="Please check your emails"
            />
          </HeadlineL>
        )}
      </CenteredFlexContainer>
    </Root>
  );
};
