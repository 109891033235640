import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { HeadlineL, HeadlineXL, LabelM } from 'atoms/typography';
import { Color } from 'style/types';

import { RivalsLightning } from './RivalsLightning';

const Root = styled.div`
  display: inline-flex;
  align-items: flex-start;
`;
const RepStyle = css`
  align-self: flex-end;
`;
const RepDefault = styled(HeadlineL).attrs({ as: 'span' })`
  ${RepStyle}
`;
const RepSmall = styled(LabelM).attrs({ as: 'span', bold: true })`
  ${RepStyle}
`;
const RepLarge = styled(HeadlineXL).attrs({ as: 'span', bold: true })`
  ${RepStyle}
`;

type Props = {
  small?: boolean;
  large?: boolean;
  disabled?: boolean;
  color?: Color;
  className?: string;
};
export const RepScore = ({
  children,
  small,
  large,
  disabled,
  color: customColor,
  className,
}: PropsWithChildren<Props>) => {
  const color =
    customColor ||
    (disabled
      ? 'rgba(var(--c-rgb-rivals-reputation), 0.5)'
      : 'var(--c-rivals-reputation)');

  return (
    <Root className={className} style={{ color }}>
      <RivalsLightning fill={color} />
      {large && <RepLarge>{children}</RepLarge>}
      {small && <RepSmall>{children}</RepSmall>}
      {!small && !large && <RepDefault>{children}</RepDefault>}
    </Root>
  );
};
