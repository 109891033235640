import { unitMapping } from 'lib/style';
import { Color, HexColor } from 'style/types';

type Props = {
  size?: keyof typeof unitMapping;
  color?: Color | HexColor;
};

export const Dot = ({ size = 1, color = 'currentColor' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 4 4"
      fill="none"
    >
      <circle cx="2" cy="2" r="2" fill={color} />
    </svg>
  );
};
