import {
  type OperationVariables,
  type QueryRef,
  type TypedDocumentNode,
  type UseReadQueryResult,
  useReadQuery,
} from '@apollo/client';
import { captureMessage } from '@sentry/react';
import { type ComponentType, Suspense } from 'react';
import { type Params, useLoaderData } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { preloadQuery } from 'contexts/graphql/Provider';
import { optimizeQuery } from 'lib/gql/optimizeQuery';

import { shouldRevalidateFn } from './shouldRevalidateFn';
import type { LoaderFunction, QueryOptions, UIOptions } from './types';

const DefaultFallbackWrapper = styled.div`
  & > * {
    height: calc(var(--100vh) - var(--current-stack-height));
  }
`;

export const DefaultFallback = () => (
  <DefaultFallbackWrapper>
    <LoadingIndicator />
  </DefaultFallbackWrapper>
);
export const withRouteQuery = <
  PageOrLayoutParams extends Params,
  P,
  TData,
  TVariables extends OperationVariables,
>(
  Component: ComponentType<P & { queryResult: UseReadQueryResult<TData> }>,
  options: {
    query: TypedDocumentNode<TData, TVariables>;
  } & QueryOptions<PageOrLayoutParams, TVariables> &
    UIOptions
): ComponentType<P> & {
  loader: LoaderFunction<PageOrLayoutParams>;
} => {
  const { query, queryOptions, preventTransitionBeforeLoaded } = options;

  const loader: LoaderFunction<PageOrLayoutParams> = async ({
    params,
    request,
  }) => {
    if (!preloadQuery.current) {
      captureMessage('Apollo preloadQuery is not initialized');
      return null;
    }

    const preloadedQueryRef = preloadQuery.current(
      optimizeQuery.current(query),
      // @ts-expect-error this apollo version types badly PreloadQueryFunction
      // so an {} object is needed even when the query asks for no variables
      {
        // Default fetchPolicy is 'cache-first', override to 'cache-and-network'
        fetchPolicy: 'cache-and-network',
        ...(queryOptions?.(params, new URL(request.url)) || {}),
      }
    );

    return preventTransitionBeforeLoaded
      ? preloadedQueryRef.toPromise()
      : preloadedQueryRef;
  };

  const PreloadedComponent: ComponentType<
    P & { preloadedQueryRef: QueryRef<TData, TVariables> }
  > = ({ preloadedQueryRef, ...otherProps }) => {
    const queryResult = useReadQuery(preloadedQueryRef);
    return <Component {...(otherProps as P)} queryResult={queryResult} />;
  };

  const ComponentWithRouteQuery = (props: P) => {
    const preloadedQueryRef = useLoaderData() as QueryRef<
      TData,
      TVariables
    > | null;

    if (!preloadedQueryRef) {
      return null;
    }
    const preloadedComponent = (
      <PreloadedComponent {...props} preloadedQueryRef={preloadedQueryRef} />
    );
    if (options.preventTransitionBeforeLoaded) {
      return preloadedComponent;
    }
    const { FallbackComponent = DefaultFallback } = options;

    return (
      <Suspense fallback={<FallbackComponent />}>{preloadedComponent}</Suspense>
    );
  };

  return Object.assign(ComponentWithRouteQuery, {
    loader,
    shouldRevalidate: queryOptions
      ? shouldRevalidateFn(
          (params, url) => queryOptions(params, url).variables!
        )
      : undefined,
  });
};
