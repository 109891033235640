import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';

const Root = styled(Vertical)`
  overflow: hidden;

  &.BLOCK.isMyRange {
    background-color: var(--c-brand-600);
  }

  &.ROW,
  &.OVERVIEW_ROW {
    background-color: transparent;
    border-radius: 0;
  }

  &.withSeparator {
    padding-top: var(--half-unit);
    padding-bottom: var(--half-unit);

    &:not(:last-child) {
      border-bottom: 1px solid var(--c-nd-150);
    }
  }
`;

const StyledSBHorizontal = styled(SBHorizontal)`
  min-height: calc(5 * var(--unit));
  gap: var(--triple-unit);
`;

export enum RewardRangeVariant {
  OVERVIEW_ROW = 'OVERVIEW_ROW',
  ROW = 'ROW',
  BLOCK = 'BLOCK',
}

type Props = {
  title: ReactNode;
  rewards?: ReactNode[];
  children?: ReactNode;
  isMyRange?: boolean;
  variant: RewardRangeVariant;
  withSeparator?: boolean;
};

export const RewardRange: FC<PropsWithChildren<Props>> = ({
  title,
  rewards,
  children,
  isMyRange,
  variant,
  withSeparator,
}: Props) => {
  return (
    <Root
      gap={1.5}
      data-testid="reward-range"
      className={classNames(variant, { isMyRange, withSeparator })}
    >
      <StyledSBHorizontal>
        <div>{title}</div>
        {rewards && <Horizontal>{rewards}</Horizontal>}
      </StyledSBHorizontal>
      {variant === RewardRangeVariant.BLOCK && children && (
        <Vertical>{children}</Vertical>
      )}
    </Root>
  );
};
