import { TypedDocumentNode, gql } from '@apollo/client';

import { useFormatAnyPosition } from '@sorare/core/src/lib/players';

import { DumbPlayerDescription } from './DumbPlayerDescription';
import { AnyPlayerDescription_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: AnyPlayerDescription_anyPlayer;
};

export const AnyPlayerDescription = ({ player }: Props) => {
  const formatPosition = useFormatAnyPosition();

  return (
    <DumbPlayerDescription
      {...player}
      positions={player.anyPositions.map(formatPosition)}
      activeClubName={player.activeClub?.name}
      sport={player.sport}
    />
  );
};

AnyPlayerDescription.fragments = {
  anyPlayer: gql`
    fragment AnyPlayerDescription_anyPlayer on AnyPlayerInterface {
      slug
      sport
      displayName
      squaredPictureUrl
      anyPositions
      shirtNumber
      activeClub {
        slug
        name
      }
    }
  ` as TypedDocumentNode<AnyPlayerDescription_anyPlayer>,
};
