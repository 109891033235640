import { TypedDocumentNode, gql } from '@apollo/client';
import { faExternalLink, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { Block } from 'atoms/layout/Block';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineL, LabelM } from 'atoms/typography';
import { ANY_SPORT_MARKET_INSTANT_BUY } from 'constants/routes';
import { useSportContext } from 'contexts/sport';

import { Content_RaffleShopItem } from './__generated__/Content.graphql';

const Wrapper = styled(Vertical).attrs({ center: true, gap: 3 })`
  padding: var(--double-unit);
`;

const RainbowText = styled.span`
  font-family: var(--brand-font);
  font-size: calc(5 * var(--unit));
  background: var(
    --Value-Experience,
    linear-gradient(
      84deg,
      #f8d3da 0%,
      #b3a9f4 28.32%,
      #fbe9fb 54.01%,
      #4f94fd 100%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TicketPicture = styled.div`
  width: min-content;
  position: relative;
  img {
    width: 200px;
  }
  ${RainbowText} {
    position: absolute;
    right: -10%;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

type Props = {
  raffleTicket: any;
};

export const Content = ({ raffleTicket }: Props) => {
  const { generateSportContextPath } = useSportContext();
  return (
    <Wrapper>
      <HeadlineL style={{ textAlign: 'center' }}>
        <FormattedMessage
          id="raffleTicket.dialog.title>"
          defaultMessage="Your {ticket}"
          values={{
            ticket: raffleTicket.name,
          }}
        />
      </HeadlineL>

      <TicketPicture>
        <img src={raffleTicket.pictureUrl} alt="" />
        <RainbowText>X{raffleTicket.myBalance}</RainbowText>
      </TicketPicture>

      {raffleTicket.description && (
        <span>
          <Block>
            <Horizontal>
              <FontAwesomeIcon icon={faInfoCircle} />
              <LabelM>{raffleTicket.description}</LabelM>
            </Horizontal>
          </Block>
        </span>
      )}

      <Vertical gap={2} style={{ width: '100%' }} center>
        <Button
          size="large"
          fullWidth
          color="primary"
          to={generateSportContextPath(ANY_SPORT_MARKET_INSTANT_BUY)}
        >
          <FormattedMessage
            id="raffleTicket.dialog.cta>"
            defaultMessage="Go to market"
          />
        </Button>
        {raffleTicket.requirementsRulesLink && (
          <a href={raffleTicket.requirementsRulesLink}>
            <Horizontal>
              <FontAwesomeIcon icon={faExternalLink} />
              <FormattedMessage
                id="EmirateVIPRaffleModal.TCs"
                defaultMessage="T&Cs"
              />
            </Horizontal>
          </a>
        )}
      </Vertical>
    </Wrapper>
  );
};

Content.fragments = {
  RaffleShopItem: gql`
    fragment Content_RaffleShopItem on RaffleShopItem {
      id
      name
      description
      infoLink
      requirementsRulesLink
      myBalance
      pictureUrl
    }
  ` as TypedDocumentNode<Content_RaffleShopItem>,
};
