import { TypedDocumentNode, gql } from '@apollo/client';

import { DumbPlayerAvatar } from '@sorare/core/src/components/player/DumbPlayerAvatar';

import { GameplayPlayerAvatar_anyPlayer } from './__generated__/index.graphql';

interface Props {
  player: GameplayPlayerAvatar_anyPlayer;
  className?: string;
}

export const PlayerAvatar = ({ player, className }: Props) => {
  return (
    <DumbPlayerAvatar
      name={player.displayName}
      avatarUrl={player.avatarPictureUrl}
      className={className}
    />
  );
};

PlayerAvatar.fragments = {
  anyPlayer: gql`
    fragment GameplayPlayerAvatar_anyPlayer on AnyPlayerInterface {
      slug
      displayName
      avatarPictureUrl
    }
  ` as TypedDocumentNode<GameplayPlayerAvatar_anyPlayer>,
};
