import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { BodyS } from 'atoms/typography';
import { useTextSize } from 'lib/text';

const StatsWrapper = styled.div`
  position: relative;
`;
const Value = styled.p`
  position: relative;
  z-index: 1;
  font-size: var(--font-size);
  line-height: var(--font-size);
  font-family: var(--brand-font);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.58);
`;

const Icon = styled(Vertical).attrs({ center: true })`
  position: absolute;
  left: 100%;
  top: 0%;
  bottom: 0%;

  &.rotateIcon {
    transform: translateX(calc(-1 * var(--unit))) rotateZ(15deg);
  }
`;

const StyledSvg = styled.svg`
  max-width: 100%;
  height: calc(7 * var(--unit));
  position: relative;
  z-index: 1;
`;
const StyledText = styled.text`
  font-size: var(--fs-56);
  font-family: var(--brand-font);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.58);
`;

type Props = {
  label: ReactNode;
  icon?: ReactNode;
  rotateIcon?: boolean;
} & (
  | {
      size: 56 | 32 | 24;
      children?: ReactNode;
    }
  | {
      size?: 'flex';
      children: string;
    }
);

const FlexStatsDisplay = ({
  children,
  label,
  icon,
}: {
  children: string;
  label: ReactNode;
  icon?: ReactNode;
}) => {
  const { width, height } = useTextSize(
    children,
    `bold 56px Rigid Square, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`
  );
  return (
    <Vertical center gap={0} className="text-center">
      <StatsWrapper>
        <Icon>{icon}</Icon>
        <StyledSvg viewBox={`0 0 ${width + 8} ${height + 8}`}>
          <StyledText x={4} y={60} fill="currentColor">
            {children}
          </StyledText>
        </StyledSvg>
      </StatsWrapper>
      <BodyS>{label}</BodyS>
    </Vertical>
  );
};

export const StatDisplay = (props: Props) => {
  const { label, icon, rotateIcon = true } = props;
  // eslint-disable-next-line react/destructuring-assignment
  if (props.size === 'flex' || typeof props.size === 'undefined') {
    const { children } = props;
    return (
      <FlexStatsDisplay label={label} icon={icon}>
        {children}
      </FlexStatsDisplay>
    );
  }
  const { children, size } = props;

  return (
    <Vertical center gap={0} className="text-center">
      <StatsWrapper>
        <Value
          style={{ '--font-size': `var(--fs-${size})` } as CSSProperties}
          className={classNames({ withIcon: !!icon })}
        >
          {children}
        </Value>
        {icon && <Icon className={classNames({ rotateIcon })}>{icon}</Icon>}
      </StatsWrapper>
      <BodyS>{label}</BodyS>
    </Vertical>
  );
};
