import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';

import { RepScore } from '../RepScore';

const Separator = styled.div`
  height: var(--double-unit);
  width: 1px;
  border: 1px solid var(--c-nd-200);
  border-radius: var(--half-unit);
`;

type Props = { win: number | null; loss: number | null };
export const RepDifference = ({ win, loss }: Props) => {
  const winDefined = typeof win === 'number';
  const lossDefined = typeof loss === 'number';
  return (
    <Horizontal gap={1.5}>
      {winDefined && (
        <LabelS as="span">
          <FormattedMessage
            id="rivals.RepDifference.win"
            defaultMessage="Win {rep}"
            values={{
              rep: (
                <RepScore small color="var(--c-green-800)">
                  {win}
                </RepScore>
              ),
            }}
          />
        </LabelS>
      )}
      {winDefined && lossDefined && <Separator />}
      {lossDefined && (
        <LabelS as="span">
          <FormattedMessage
            id="rivals.RepDifference.loss"
            defaultMessage="Loss {rep}"
            values={{
              rep: (
                <RepScore small color="var(--c-red-800)">
                  {loss}
                </RepScore>
              ),
            }}
          />
        </LabelS>
      )}
    </Horizontal>
  );
};
