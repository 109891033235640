import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import { Form } from '../Form';
import { useCancelSo5NoCardLineup } from './useCancelSo5NoCardLineup';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.Cancel.title',
    defaultMessage: 'A new team will be proposed to you by email.',
  },
});

interface Props {
  so5LineupId: string;
}

export const NoCardEntryCancel = ({ so5LineupId }: Props) => {
  const [cancelLineup, loading] = useCancelSo5NoCardLineup();
  const [mutationResult, setMutationResult] =
    useState<Awaited<ReturnType<typeof cancelLineup>>>();

  useEffect(() => {
    cancelLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
    }).then(setMutationResult);
  }, [so5LineupId, cancelLineup]);

  return (
    <Form
      title={messages.title}
      postAction="email"
      loading={loading}
      {...mutationResult}
    />
  );
};
