import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, LabelS } from 'atoms/typography';
import { CardImg } from 'components/card/CardImg';
import { getHumanReadableSerialNumber } from 'lib/cards';
import { format } from 'lib/seasons';

import { WarningRow_anyCard } from './__generated__/index.graphql';

const Wrapper = styled(Vertical)`
  background: var(--c-nd-100);
  border-radius: var(--unit);
`;
const PlayerInfo = styled(Horizontal).attrs({ gap: 1.5 })`
  padding: var(--unit);
`;
const Warning = styled(Horizontal)`
  background: var(--c-nd-150);
  border-radius: 0 0 var(--unit) var(--unit);
  padding: var(--unit);
  &.error {
    background: rgba(var(--c-rgb-red-600), 0.1);
  }
`;

const PlayerImg = styled(CardImg)`
  width: 40px;
`;

type Props = {
  card: WarningRow_anyCard;
  warning: ReactNode;
  warningLevel?: 'error' | 'warning';
};

export const WarningRow = ({
  card,
  warning,
  warningLevel = 'warning',
}: Props) => {
  const { seasonYear, singleCivilYear, pictureUrl, anyPlayer } = card;

  return (
    <Wrapper>
      <PlayerInfo>
        {pictureUrl && <PlayerImg src={pictureUrl} alt="" width={80} />}
        <div>
          <BodyM>{anyPlayer?.displayName}</BodyM>
          <LabelS color="var(--c-nd-600)">
            {`${format(seasonYear, {
              singleCivilYear,
            })} - ${getHumanReadableSerialNumber(card)}`}
          </LabelS>
        </div>
      </PlayerInfo>
      <Warning className={warningLevel}>{warning}</Warning>
    </Wrapper>
  );
};

WarningRow.fragments = {
  anyCard: gql`
    fragment WarningRow_anyCard on AnyCardInterface {
      slug
      seasonYear
      singleCivilYear
      pictureUrl
      anyPlayer {
        slug
        displayName
      }
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<WarningRow_anyCard>,
};
