import { useTimeLeft } from 'hooks/useTimeLeft';
import { shortTimeLeftMessages } from 'lib/glossary';

type Props = { time: Date };
export const ShortTimeLeft = ({ time }: Props) => {
  const { message } = useTimeLeft(time, {
    customMessages: shortTimeLeftMessages,
  });
  return message;
};
