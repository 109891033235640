import { useTypedParams } from '@sorare/routing';

import { Accept as AcceptLineupForm } from '@sorare/gameplay/src/components/noCardRoute/AcceptLineupForm';

import { PageParams } from './__generated__/routeParams';

const Accept = () => {
  const { so5LineupId } = useTypedParams<PageParams>();

  return <AcceptLineupForm so5LineupId={so5LineupId} />;
};

export default Accept;
