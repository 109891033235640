import { useTypedParams } from '@sorare/routing';

import { NoCardEntryCancel as CancelLineupForm } from '@sorare/gameplay/src/components/noCardRoute/CancelLineupForm';

import { PageParams } from './__generated__/routeParams';

const NoCardEntryCancel = () => {
  const { so5LineupId } = useTypedParams<PageParams>();

  return <CancelLineupForm so5LineupId={so5LineupId} />;
};

export default NoCardEntryCancel;
